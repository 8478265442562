import { InternalMain } from "../components/Main";

export default function Location() {
    return(
        <InternalMain pageName="Location">
            <div className="Inner-Content">
                <h4>Conference</h4>
                <h4>Thursday evening</h4>
                <p>TBD</p>
            </div>
        </InternalMain>
    )
}
